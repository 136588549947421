@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
  font-family: 'Montserrat', sans-serif;
  --primary-color: #222D41;
  --secondary-color: #3B556D;
  --tertiary-color: #B1BAC7;
}

.App {
  margin: 0 auto;
  max-width: 1440px;
  min-height: calc(100vh - 100px);
}

body {
  background-color: var(--primary-color);
  margin: 0 auto;
}
