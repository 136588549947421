.Live {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    margin-bottom: 30px;
    background-color: var(--secondary-color);
    border-radius: 10px;
}

.title_live {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    color: var(--tertiary-color);
    margin-bottom: 20px;
    margin-top: 20px;
    padding-left: 20px;
}

.Live_container {
    display: flex;
    justify-content: space-evenly;
    margin: 0;
    margin-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: var(--secondary-color);
    border-radius: 10px;  
}

.FirstLive {
    justify-content: center;
    align-items: center;
    color: var(--tertiary-color);
    margin: 0;
    width: 560px;
    height: 315px;
}

/* MediaQueries Desktop Small*/
@media screen and (max-width: 1210px) {

    .Live_container {
        flex-direction: column;
        justify-content: space-between;
    }

    .FirstLive {
        width: auto;
        height: 315px;
    }
}

/* MediaQueries Tablet*/
@media screen and (max-width: 768px) { 
    .title_live {
        font-size: 20px;
    }

    .Live_container {
        flex-direction: column;
        justify-content: space-between;
    }

    .FirstLive {
        width: auto;
        height: 315px;
    }
}

/* MediaQueries Mobile*/
@media screen and (max-width: 480px) { 
    .title_live {
        font-size: 20px;
        margin-bottom: 10px;
        margin-top: 10px;
        padding-left: 10px;
    }

    .Live_container {
        flex-direction: column;
        margin-bottom: 10px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .FirstLive {
        width: auto;
        height: 225px;
    }
}