.Home {
    padding: 20px;
}

.About_iss_container {
    background-color: var(--secondary-color);
    border-radius: 10px;
    padding: 20px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.About_iss_title {
    font-size: 25px;
    color: var(--tertiary-color);
    margin-top: 0;
    margin-bottom: 20px;
}

.About_iss_text {
    font-size: 20px;
    color: var(--tertiary-color);
    margin: 0;
    line-height: 1.5;
}

/* Media Queries pour les appareils mobiles */
@media screen and (max-width: 768px) {
    .About_iss_text {
        font-size: 16px;
    }
}

/* Media Queries pour les appareils plus petits */
@media screen and (max-width: 480px) {

    .About_iss_container {
        padding: 10px;
    }
    .About_iss_title {
        font-size: 20px;
    }

    .About_iss_text {
        font-size: 14px;
    }
}
