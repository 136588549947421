.News {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    margin-bottom: 30px;
    background-color: var(--secondary-color);
    border-radius: 10px;
}

.News_title {
    font-size: 25px;
    color: var(--tertiary-color);
    margin-bottom: 20px;
    margin-top: 20px;
    padding-left: 20px;
}

.News_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 10px;
}

.News_article {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: auto;
    height: auto;
    border-radius: 10px;
    margin-bottom: 20px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
}

.News_image {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10%;
    height: 50px;
    width: 50px;
    padding: 10px;
    margin: 0;
}

.News_article_title {
    color: var(--tertiary-color);
    padding: 10px;
    display: flex;
    align-items: center;
    margin: 0;
    font-size: 20px;
}

.News_article_summary {
    color: var(--tertiary-color);
    font-size: 15px;
    padding: 10px;
    margin: 0;
}

.News_article_link {
    color: var(--tertiary-color);
    font-size: 15px;
    padding: 10px;
    margin: 0;
}

/* MediaQueries Mobile*/
@media screen and (max-width: 480px) { 
    .News_title {
        font-size: 20px;
        margin-bottom: 10px;
        margin-top: 10px;
        padding-left: 10px;
    }

    .News_container {
        margin-left: 10px;
        margin-right: 10px;
    }

    .News_article {
        margin-bottom: 10px;
    }

    .News_article_title {
        font-size: 16px;
    }

    .News_article_summary {
        font-size: 14px;
    }

    .News_article_link {
        font-size: 13px;
    }
}