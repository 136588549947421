.expedition_infos {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 10px;
    background: var(--secondary-color);
    border-radius: 10px;
    margin-top: 30px;
    align-items: center;
}

.expedition_title {
    display: flex;
    justify-content: center;
    font-size: 24px;
    color: var(--tertiary-color);
    margin: 20px 0 20px 0;
}

.expedition_patch {
    display: flex;
    margin: 0 0 20px 0;
    height: 300px;
    width: 300px;
}

.expedition_date_title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: var(--tertiary-color);
    margin: 0 0 20px 0;
}

.expedition_date_text {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: var(--tertiary-color);
    margin: 0 0 20px 0;
}

.expedition_ship_title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: var(--tertiary-color);
    margin: 0 0 20px 0;
}

.expedition_ship_text {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: var(--tertiary-color);
    margin: 0 0 20px 0;
}

/* MediaQueries Tablett */
@media screen and (max-width: 768px) {
    .expedition_title {
        font-size: 20px;
        margin: 10px 0 10px 0;
    }

    .expedition_patch {
        margin: 0 0 10px 0;
        height: 250px;
        width: 250px;
    }

    .expedition_date_title {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        margin: 0 0 10px 0;
    }
    
    .expedition_date_text {
        font-size: 20px;
        margin: 0 0 10px 0;
    }
    
    .expedition_ship_title {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        margin: 0 0 10px 0;
    }
    
    .expedition_ship_text {
        font-size: 20px;
        margin: 0 0 10px 0;
    }  
}

/* MediaQueries Mobile */
@media screen and (max-width: 480px) {
    .expedition_title {
        font-size: 16px;
        margin: 10px 0 10px 0;
    }

    .expedition_patch {
        margin: 0 0 10px 0;
        height: 200px;
        width: 200px;
    }

    .expedition_date_title {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        margin: 0 0 10px 0;
    }
    
    .expedition_date_text {
        font-size: 14px;
        margin: 0 0 10px 0;
    }
    
    .expedition_ship_title {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        margin: 0 0 10px 0;
    }
    
    .expedition_ship_text {
        font-size: 14px;
        margin: 0 0 10px 0;
    }
    
}