.collapse_container {
    width: 100%;
    background-color: var(--secondary-color);
    border-radius: 10px;
    margin-top: 30px;
}

.collapse_content {
    display: flex;
    justify-content: space-between;
    background-color: var(--secondary-color);
    border-radius: 10px;
    padding: 0px 20px 0px 20px;
    height: 100px;
}

.collapse_title {
    display: flex;
    font-size: 24px;
    font-weight: 500;
    color: var(--tertiary-color);
    align-items: center;
}

.collapse_arrow {
    cursor: pointer;
    width: 26px;
}

.collapse_texte {
    font-size: 24px;
    font-weight: 400;
    color: var(--tertiary-color);
    max-height: 300px;
    background-color: var(--primary-color);
    padding: 20px;
    border-radius: 0 0 10px 10px;
    border: 2px solid var(--secondary-color);
}

.collapse_arrow_rot {
    cursor: pointer;
    transform: rotate(180deg);
    width: 26px;
}

/* MediaQueries Tablet */
@media screen and (max-width: 768px) {
    .collapse_container {
        width: 100%;
    }

    .collapse_content {
        border-radius: 5px;
        height: 50px;
        align-items: center;
    }

    .collapse_title {
        font-size: 20px;
    }

    .collapse_texte {
        font-size: 20px;
        padding: 20px;
    }

    .collapse_arrow {
        width: 20px;
    }

    .collapse_arrow_rot {
        width: 20px;
    }
}

/* MediaQueries Mobile */
@media screen and (max-width: 480px) {
    .collapse_container {
        width: 100%;
    }

    .collapse_content {
        border-radius: 5px;
        height: 50px;
        align-items: center;
        padding: 0px 10px 0px 10px;
    }

    .collapse_title {
        font-size: 16px;
    }

    .collapse_texte {
        font-size: 16px;
        padding: 10px 10px 10px 10px;
    }

    .collapse_arrow {
        width: 16px;
    }

    .collapse_arrow_rot {
        width: 16px;
    }
}