.carrousel {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    overflow: hidden;
    background-color: var(--secondary-color);
    position: relative;
    padding: 20px;
    border-radius: 10px;
}

.carrousel_counter {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: absolute;
    bottom: 20px;
    left: 0;
    width: 100%;
    height: 50px;
    font-size: 20px;
    color: var(--tertiary-color);
}

.credit {
    margin: 0;
    font-size: 10px;
}

.buttons_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

.Carrousel_image {
    width: 600px;
    height: 300px;
    object-fit: cover;
}

.carrousel_prev {
    width: 46px;
    height: 79px;
    cursor: pointer;
    margin-left: 20px;
    color: var(--tertiary-color);
}

.carrousel_next {
    width: 46px;
    height: 79px;
    cursor: pointer;
    margin-right: 20px;
    color: var(--tertiary-color);
}

/* MediaQueries Tablet */
@media (max-width: 768px) {
    .carrousel {
        padding: 10px;
    }

    .Carrousel_image {
        width: 400px;
        height: 200px;
    }

    .carrousel_prev {
        width: 23px;
        height: 39px;
        margin-left: 5px;
    }

    .carrousel_next {
        width: 23px;
        height: 39px;
        margin-right: 5px;
    }
}

/* MediaQueries Mobile */
@media screen and (max-width: 480px) {
    .carrousel {
        padding: 10px;
    }

    .Carrousel_image {
        width: 300px;
        height: 150px;
    }

    .carrousel_prev {
        width: 23px;
        height: 39px;
        margin-left: 5px;
    }

    .carrousel_next {
        width: 23px;
        height: 39px;
        margin-right: 5px;
    }
}