.navigation-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--secondary-color);
    border-radius: 10px;
    width: 100%;
    height: 100%;
}

/* Si un des boutons est manquant, on centre le bouton restant */
.navigation-buttons .nav-button:only-child, 
.navigation-buttons .current-mission:only-child {
    margin: 0 auto;
}

.nav-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    text-decoration: none;
    color: var(--tertiary-color);
    margin: 20px;
}

.previous-button-image {
    width: 50px;
    height: 50px;
}

.next-button-image {
    width: 50px;
    height: 50px;
}

.current-mission {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    margin: 20px;
}

.button-text-current {
    display: flex;
    font-weight: bold;
    font-size: 24px;
    color: var(--primary-color);
}

.current-button-image {
    width: 50px;
    height: 50px;
}

.button-text {
    display: flex;
    font-weight: bold;
    font-size: 24px;
}

/* MediaQueries Tablet */
@media screen and (max-width: 768px) {
    .previous-button-image {
        width: 40px;
        height: 40px;
    }

    .current-button-image {
        width: 40px;
        height: 40px;
    }
    
    .next-button-image {
        width: 40px;
        height: 40px;
    }

    .button-text {
        font-size: 20px;
    }

    .button-text-current {
        font-size: 20px;
    }
}

/* MediaQueries Mobile */
@media screen and (max-width: 480px) {
    .nav-button {
        margin: 10px;
    }

    .previous-button-image {
        width: 30px;
        height: 30px;
    }

    .current-button-image {
        width: 30px;
        height: 30px;
    }

    .current-mission {
        margin: 10px;
    }
    
    
    .next-button-image {
        width: 30px;
        height: 30px;
    }

    .button-text {
        font-size: 14px;
    }

    .button-text-current {
        font-size: 14px;
    }
}