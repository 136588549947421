.header {
    background-color: var(--secondary-color);
    height: 120px;
    border-radius: 0 0 10px 10px;
}

.header_link {
    text-decoration: none;
    color: var(--tertiary-color);
}

.header_title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 50px;
    width: 100%;
    margin: 0;
    color: var(--tertiary-color);
}

.header_navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
}

.header_nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50%;
    max-width: 400px;
    margin: 0 auto;
}

.navbar_list{
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    font-size: 24px;
    padding: 0;
    font-weight: 500;
    margin: 0;
    width: 100%;
}

.navbar_link {
    display: flex;
    cursor: pointer;
    text-decoration: none;
    color: var(--tertiary-color);
}

.navbar_link_active {
    display: inline;
    position: relative;
    cursor: pointer;
    text-decoration: none;
    color: var(--primary-color);
    text-decoration: underline 2px;
}

/* MediaQueries Tablette */
@media screen and (max-width: 768px) {
    .header {
        height: 110px;
    }

    .header_title {
        font-size: 40px;
    }

    .header_nav {
        width: 70%;
    }

    .navbar_list {
        font-size: 20px;
    }
}

/* MediaQueries Mobile */
@media screen and (max-width: 480px) {
    .header {
        height: 90px;
    }

    .header_title {
        font-size: 30px;
    }

    .header_nav {
        width: 90%;
    }

    .navbar_list {
        font-size: 16px;
    }
}