.footer {
    background-color: var(--secondary-color);
    padding: 20px 0;
    text-align: center;
    border-radius: 10px 10px 0 0;
    margin-top: 30px;
}

.footer_content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: var(--tertiary-color);
    font-size: 14px;
}

.footer_content p {
    margin: 0;
}

.footer_link {
    color: var(--tertiary-color);
    font-weight: 500;
    text-decoration: none;
}